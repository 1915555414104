export default function showNavigationWhenScrollingUp() {
  let previousScrollPosition = window.pageYOffset;
  window.addEventListener("scroll", () => {
    const resetPosition = 0;
    const start = 100;
    const currentScrollPosition = window.pageYOffset;

    if (
      previousScrollPosition > currentScrollPosition ||
      currentScrollPosition < start ||
      document.activeElement === document.querySelector(".navigation__burger")
    ) {
      document.querySelector(".navigation__nav").style.transform =
        "translateY(0)";
    } else {
      document.querySelector(".navigation__nav").style.transform =
        "translateY(calc(-100% - 1px))";
    }
    if (currentScrollPosition <= resetPosition + start) {
      document
        .querySelector(".navigation__nav")
        .classList.remove("navigation__nav--scrolling-up");
    } else {
      document
        .querySelector(".navigation__nav")
        .classList.add("navigation__nav--scrolling-up");
    }
    previousScrollPosition = currentScrollPosition;
  });
}
