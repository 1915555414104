export default function tabs() {
  const tabsBlocks = document.querySelectorAll(".tabs");

  tabsBlocks.forEach((tabsBlock) => {
    const tabs = tabsBlock.querySelectorAll(".tabs__tab");
    const tabLabelsContainer = tabsBlock.querySelector(".tabs__tab-labels");
    const tabLabels = tabsBlock.querySelectorAll(".tabs__tab-label");
    const previousButton = tabsBlock.querySelector(".tabs__arrow--previous");
    const nextButton = tabsBlock.querySelector(".tabs__arrow--next");

    let activeTab = 0;

    disableArrows(activeTab, tabs, previousButton, nextButton);
    setActiveTab(activeTab, tabs, tabLabels, tabLabelsContainer);

    tabLabels.forEach((tabLabel, index) => {
      tabLabel.addEventListener("click", () => {
        disableArrows(index, tabs, previousButton, nextButton);
        setActiveTab(index, tabs, tabLabels, tabLabelsContainer);
        activeTab = index;
      });
    });

    previousButton.addEventListener("click", () => {
      let previousTab = activeTab - 1;
      if (previousTab < 0) {
        previousTab = 0;
      }
      disableArrows(previousTab, tabs, previousButton, nextButton);
      setActiveTab(previousTab, tabs, tabLabels, tabLabelsContainer);
      activeTab = previousTab;
    });

    nextButton.addEventListener("click", () => {
      let nextTab = activeTab + 1;
      if (nextTab > tabs.length - 1) {
        nextTab = tabs.length - 1;
      }
      disableArrows(nextTab, tabs, previousButton, nextButton);
      setActiveTab(nextTab, tabs, tabLabels, tabLabelsContainer);
      activeTab = nextTab;
    });
  });
}

function setActiveTab(index, tabs, tabLabels, tabLabelsContainer) {
  const tabToBeActivated = index;
  tabs.forEach((tab, index) => {
    if (index == tabToBeActivated) {
      tab.classList.remove("tabs__tab--inactive");
    } else {
      tab.classList.add("tabs__tab--inactive");
    }
  });
  tabLabels.forEach((tab, index) => {
    if (index == tabToBeActivated) {
      tab.classList.remove("tabs__tab-label--inactive");
      tabLabelsContainer.scrollLeft = tab.offsetLeft;
    } else {
      tab.classList.add("tabs__tab-label--inactive");
    }
  });
}

function disableArrows(index, tabs, previousButton, nextButton) {
  if (index == tabs.length - 1) {
    nextButton.querySelector(".icon").classList.add("icon--disabled");
    previousButton.querySelector(".icon").classList.remove("icon--disabled");
  } else if (index == 0) {
    previousButton.querySelector(".icon").classList.add("icon--disabled");
    nextButton.querySelector(".icon").classList.remove("icon--disabled");
  } else {
    nextButton.querySelector(".icon").classList.remove("icon--disabled");
    previousButton.querySelector(".icon").classList.remove("icon--disabled");
  }
}
