export default function multicolumn() {
  const multicolumnBlocks = document.querySelectorAll(".multi-column");

  multicolumnBlocks.forEach((multicolumnBlock) => {
    const columns = multicolumnBlock.querySelectorAll(".multi-column__item");

    let visibleColumns = returnVisibleColumns();

    if (columns.length > visibleColumns) {
      const previousButton = multicolumnBlock.querySelector(
        ".multi-column__arrow--previous"
      );
      const nextButton = multicolumnBlock.querySelector(
        ".multi-column__arrow--next"
      );

      if (!previousButton || !nextButton) {
        return;
      }

      let activeColumn = 0;

      disableArrows(activeColumn, columns, previousButton, nextButton);

      columns.forEach((column, index) => {
        if (index > visibleColumns - 1) {
          column.classList.add("multi-column__item--hidden");
        }
      });

      previousButton.addEventListener("click", () => {
        let previousColumn = activeColumn - 1;
        if (previousColumn < 0) {
          previousColumn = 0;
        }
        disableArrows(
          previousColumn,
          columns,
          previousButton,
          nextButton,
          visibleColumns
        );
        setActiveColumn(previousColumn, columns, visibleColumns);
        activeColumn = previousColumn;
      });

      nextButton.addEventListener("click", () => {
        let nextColumn = activeColumn + 1;
        if (nextColumn > columns.length - visibleColumns) {
          nextColumn = columns.length - visibleColumns;
        }
        disableArrows(
          nextColumn,
          columns,
          previousButton,
          nextButton,
          visibleColumns
        );
        setActiveColumn(nextColumn, columns, visibleColumns);
        activeColumn = nextColumn;
      });
    }
  });
}

function returnVisibleColumns() {
  if (window.matchMedia("(max-width: 1279px)").matches) {
    return 2;
  } else {
    return 3;
  }
}

function setActiveColumn(columnToBeActivated, columns, visibleColumns) {
  columns.forEach((column, index) => {
    if (
      index >= columnToBeActivated &&
      index < columnToBeActivated + visibleColumns
    ) {
      column.classList.remove("multi-column__item--hidden");
    } else {
      column.classList.add("multi-column__item--hidden");
    }
  });
}

function disableArrows(
  index,
  columns,
  previousButton,
  nextButton,
  visibleColumns
) {
  const nextButtonIcon = nextButton.querySelector(".icon");
  const previousButtonIcon = previousButton.querySelector(".icon");
  if (!nextButtonIcon || !previousButtonIcon) {
    return;
  }
  if (index == columns.length - visibleColumns) {
    nextButtonIcon.classList.add("icon--disabled");
    previousButtonIcon.classList.remove("icon--disabled");
  } else if (index == 0) {
    previousButtonIcon.classList.add("icon--disabled");
    nextButtonIcon.classList.remove("icon--disabled");
  } else {
    nextButtonIcon.classList.remove("icon--disabled");
    previousButtonIcon.classList.remove("icon--disabled");
  }
}
