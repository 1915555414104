export default function cookieBar() {
  const googleAnalyticsId =
    document.querySelector("body").dataset.googleAnalyticsId;
  const cookiebar = document.querySelector(".cookiebar");

  window[`ga-disable-${googleAnalyticsId}`] = true;
  window.dataLayer = window.dataLayer || [];

  if (cookiebar) {
    const cookieName = "cookiebarAccepted";
    if (isCookiePresent(cookieName)) {
      trackPageInGoogleAnalytics();
    }
    if (!isCookiePresent(cookieName)) {
      cookiebar.classList.add("cookiebar--active");
    }
    document
      .querySelector(".cookiebar__button--agree")
      .addEventListener("click", (event) => {
        event.preventDefault();
        document.cookie = `${cookieName}=true`;
        closeCookiebar(cookiebar);
        trackPageInGoogleAnalytics();
      });
  }

  function gtag(...args) {
    window.dataLayer.push(args);
  }

  function trackPageInGoogleAnalytics() {
    window[`ga-disable-${googleAnalyticsId}`] = false;
    gtag("js", new Date());
    gtag("config", googleAnalyticsId);
  }
}

function closeCookiebar(cookiebar) {
  cookiebar.classList.remove("cookiebar--active");
}

function isCookiePresent(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let c = cookieArray[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}
