export default function accordion() {
  const accordions = document.querySelectorAll(".accordion__items");

  accordions.forEach((accordion) => {
    const accordionItems = accordion.querySelectorAll(
      ".accordion__item-header"
    );
    accordionItems.forEach((accordionItem) => {
      accordionItem.parentElement.classList.toggle(
        "accordion__item--collapsed"
      );
      accordionItem.addEventListener("click", () => {
        accordionItem.parentElement.classList.toggle(
          "accordion__item--collapsed"
        );
      });
    });
  });
}
