import cookieBar from "./cookiebar";
import accordion from "../../site/plugins/custom-components/src/blocks/accordion/accordion.js";
import tabs from "../../site/plugins/custom-components/src/blocks/tabs/tabs.js";
import team from "../../site/plugins/custom-components/src/blocks/team/team.js";
import industries from "../../site/plugins/custom-components/src/blocks/industries/industries.js";
import multicolumn from "../../site/plugins/custom-components/src/blocks/multi-column/multi-column.js";
import openAndCloseNavigationOverlay from "./open-and-close-navigation-overlay";
import showAndHideDropdownMenuItemsOnHover from "./show-and-hide-dropdown-menu-items-on-hover";
import showNavigationWhenScrollingUp from "./show-navigation-when-scrolling-up";
import toggleNavigationElements from "./toggle-navigation-elements";
import videoOverlay from "./video-overlay";

document.addEventListener("DOMContentLoaded", () => {
  openAndCloseNavigationOverlay();
  showAndHideDropdownMenuItemsOnHover();
  showNavigationWhenScrollingUp();
  toggleNavigationElements();
  accordion();
  cookieBar();
  tabs();
  industries();
  team();
  videoOverlay();
  multicolumn();
});
