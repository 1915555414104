export default function toggleNavigationElements() {
  const MOBILE_VIEWPORT = 910;

  _toggleNavigationElements();

  window.addEventListener("resize", () => {
    _toggleNavigationElements();
  });

  function _toggleNavigationElements() {
    if (window.innerWidth <= MOBILE_VIEWPORT) {
      const dropdownElements = Array.from(
        document.querySelectorAll(".navigation__slidein-nav-label")
      );

      dropdownElements.forEach((dropdownElement) => {
        dropdownElement.addEventListener("click", (event) => {
          event.preventDefault();
          dropdownElement.focus();

          if (
            dropdownElement.classList.contains(
              "navigation__slidein-nav-label--active"
            )
          ) {
            dropdownElement.classList.remove(
              "navigation__slidein-nav-label--active"
            );
            document.activeElement.blur();
          } else {
            dropdownElement.classList.add(
              "navigation__slidein-nav-label--active"
            );
            dropdownElement.focus();
          }
        });
      });
    }
  }
}
