export default function industries() {
  const tabsBlocks = document.querySelectorAll(".industries");

  tabsBlocks.forEach((tabsBlock) => {
    const tabs = tabsBlock.querySelectorAll(".industries__tab");
    const tabLabelsContainer = tabsBlock.querySelector(
      ".industries__tab-labels"
    );
    const tabLabels = tabsBlock.querySelectorAll(".industries__tab-label");
    const previousButton = tabsBlock.querySelector(
      ".industries__arrow--previous"
    );
    const nextButton = tabsBlock.querySelector(".industries__arrow--next");

    let activeTab = 0;

    disableArrows(activeTab, tabs, previousButton, nextButton);
    setActiveTab(activeTab, tabs, tabLabels, tabLabelsContainer);

    tabLabels.forEach((tabLabel, index) => {
      tabLabel.addEventListener("click", () => {
        disableArrows(index, tabs, previousButton, nextButton);
        setActiveTab(index, tabs, tabLabels, tabLabelsContainer);
        activeTab = index;
      });
    });

    previousButton.addEventListener("click", () => {
      let previousTab = activeTab - 1;
      if (previousTab < 0) {
        previousTab = 0;
      }
      disableArrows(previousTab, tabs, previousButton, nextButton);
      setActiveTab(previousTab, tabs, tabLabels, tabLabelsContainer);
      activeTab = previousTab;
    });

    nextButton.addEventListener("click", () => {
      let nextTab = activeTab + 1;
      if (nextTab > tabs.length - 1) {
        nextTab = tabs.length - 1;
      }
      disableArrows(nextTab, tabs, previousButton, nextButton);
      setActiveTab(nextTab, tabs, tabLabels, tabLabelsContainer);
      activeTab = nextTab;
    });

    tabs.forEach((element) => {
      const expandLink = element.querySelector(".industries__show-more");
      const collapseLink = element.querySelector(".industries__show-less");
      const activeTabExpandable =
        document
          .getElementById(expandLink.dataset.id)
          .querySelector(".industries__expandable") ?? null;

      if (expandLink && activeTabExpandable) {
        expandLink.addEventListener("click", () => {
          expandLink.classList.toggle("industries__show-more--collapsed");
          collapseLink.classList.toggle("industries__show-less--collapsed");
          activeTabExpandable.classList.toggle(
            "industries__expandable--collapsed"
          );
        });
        collapseLink.addEventListener("click", () => {
          expandLink.classList.toggle("industries__show-more--collapsed");
          collapseLink.classList.toggle("industries__show-less--collapsed");
          activeTabExpandable.classList.toggle(
            "industries__expandable--collapsed"
          );
        });
      }
    });
  });
}

function setActiveTab(index, tabs, tabLabels, tabLabelsContainer) {
  const tabToBeActivated = index;
  tabs.forEach((tab, index) => {
    if (index == tabToBeActivated) {
      tab.classList.remove("industries__tab--inactive");
    } else {
      tab.classList.add("industries__tab--inactive");
    }
  });
  tabLabels.forEach((tab, index) => {
    if (index == tabToBeActivated) {
      tab.classList.remove("industries__tab-label--inactive");
      tabLabelsContainer.scrollLeft = tab.offsetLeft;
    } else {
      tab.classList.add("industries__tab-label--inactive");
    }
  });
}

function disableArrows(index, tabs, previousButton, nextButton) {
  if (index == tabs.length - 1) {
    nextButton.querySelector(".icon").classList.add("icon--disabled");
    previousButton.querySelector(".icon").classList.remove("icon--disabled");
  } else if (index == 0) {
    previousButton.querySelector(".icon").classList.add("icon--disabled");
    nextButton.querySelector(".icon").classList.remove("icon--disabled");
  } else {
    nextButton.querySelector(".icon").classList.remove("icon--disabled");
    previousButton.querySelector(".icon").classList.remove("icon--disabled");
  }
}
