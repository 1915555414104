export default function videoOverlay() {
  const mediaWithOverlays = document.querySelectorAll(".video-overlay");

  [].forEach.call(mediaWithOverlays, (mediaWithOverlay) => {
    const modal = mediaWithOverlay.querySelector(".video-overlay__overlay");

    if (!modal) {
      return;
    }

    const closeButton = modal.querySelector(".video-overlay__close");

    if (!closeButton) {
      return;
    }

    mediaWithOverlay.addEventListener("click", () => {
      modal.classList.add("video-overlay__overlay--active");
      modal.setAttribute("aria-hidden", "false");
    });

    modal.addEventListener("click", (event) => {
      event.stopPropagation();

      if (event.target === modal) {
        closeModal(modal);
      }
    });

    closeButton.addEventListener("click", () => {
      closeModal(modal);
    });
  });
}

function closeModal(modal) {
  modal.classList.remove("video-overlay__overlay--active");
  modal.setAttribute("aria-hidden", "true");
  pauseVideo(modal.querySelector(".video-overlay__video"));
  stopIframeVideo(modal.querySelector(".video-overlay__iframe"));
}

function pauseVideo(video) {
  if (!video) {
    return;
  }

  video.pause();
}

function stopIframeVideo(iframe) {
  if (!iframe) {
    return;
  }

  iframe.contentWindow.postMessage(
    '{"event":"command","func":"stopVideo","args":""}',
    "*"
  );
}
