export default function team() {
  const teams = document.querySelectorAll(".team__members");

  teams.forEach((team) => {
    const members = team.querySelectorAll(".team__member");
    members.forEach((member) => {
      const showMoreButton = member.querySelector(".team__member-show-more");
      const showLessButton = member.querySelector(".team__member-show-less");
      const biography = member.querySelector(".team__member-bio");
      biography.classList.add("team__member-bio--collapsed");
      showMoreButton.addEventListener("click", () => {
        biography.classList.remove("team__member-bio--collapsed");
        showMoreButton.classList.add("team__member-show-more--hidden");
      });
      showLessButton.addEventListener("click", () => {
        biography.classList.add("team__member-bio--collapsed");
        showMoreButton.classList.remove("team__member-show-more--hidden");
      });
    });
  });
}
