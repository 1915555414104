const MOBILE_WIDTH = 800;

export default function openAndCloseNavigationOverlay() {
  const nav = document.querySelector(".navigation__nav");
  const menuButton = document.querySelector(".navigation__burger");

  if (!nav || !menuButton) {
    return;
  }

  menuButton.addEventListener("click", () => {
    if (window.innerWidth < MOBILE_WIDTH) {
      nav.classList.toggle("navigation__nav--active");
    }
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth > MOBILE_WIDTH) {
      nav.classList.remove("navigation__nav--active");
    }
  });
}
